import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "timeago.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { scholershipAndOfferAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import moment from "moment";
import SocialShareButton from "../../Component/SocialShareButton";

function ScholarshipOffers() {
  const urlsforShare = window.location.href;
  const dispatch = useDispatch();
  const history = useHistory();
  const lastLocation = useLocation();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const baseurl = URL.API_BASE_URL;

  const [modelData, setmodelData] = useState<any>("");

  const getAllCoupon: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getAllCoupon
      ? state.scholershipAndOffer.getAllCoupon
      : []
  );
  const appliedScholarData: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.appliedScholarShip
      ? state.scholershipAndOffer.appliedScholarShip
      : []
  );

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );

  const hadleDisableApply = (sch_id: any) => {
    let _dt = appliedScholarData.find((ele) => ele?.scholarship_id == sch_id);
    if (_dt) {
      return true;
    } else {
      return false;
    }
  };
  const hendleApplyNow = (e: any, id: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      let _dt = {
        student_id: user?.id,
        scholarship_id: id,
      };
      dispatch(scholershipAndOfferAction.applyScholar(_dt));
    }
  };

  const activeCoupon =
    getAllCoupon &&
    getAllCoupon?.filter((data: any) => {
      return data?.status == 1;
    });

  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getAllCoupon({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    if (user?.id) {
      dispatch(
        scholershipAndOfferAction.getAppliedScholar({ student_id: user?.id })
      );
    }
    return () => {};
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {getOFferAndScholar && getOFferAndScholar?.length > 0 && (
        <div>
          <section className="categories section">
            <div className="container">
              <div
                className="headingmains text-center"
                // data-aos="fade-right"
                // data-aos-duration="5000"
                // data-aos-offset="2"
              >
                <h2 className="titlewithline">
                  Scholarship & Offers
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.333"
                      height="33.667"
                      viewBox="0 0 33.333 33.667"
                    >
                      <path
                        id="Icon_material-local-offer"
                        data-name="Icon material-local-offer"
                        d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                        transform="translate(-3 -3)"
                        fill="#fbb415"
                      />
                    </svg>
                  </span>
                </h2>
                <p className="text-center pl-2 pr-2">
                  Discover how we achieve upto 100% Fee Scholarships to the best
                  international universities for our students. Learn their
                  stories!{" "}
                </p>
              </div>
              <div className="all-center sub-section pb-0">
                <div className="col-md-12 all-center pb-5">
                  <div
                    // className="offerslider"
                    // className="offerslider all-center aos-init aos-animate"
                    // data-aos="fade-right"
                    // data-aos-duration="5000"
                    // data-aos-offset="1"
                    className="offerslider"
                    data-aos="fade-right"
                    // data-aos-duration="500"
                  >
                    <Slider {...settings}>
                      {getOFferAndScholar &&
                        getOFferAndScholar?.map((data: any, i: any) => {
                          return (
                            <div key={i}>
                              <div className="offers-item yello title">
                                <figure className="feat-text">
                                  {data?.type == "Offers" ? (
                                    data?.coupon_icon !== null ? (
                                      <img
                                        className="scale homeOffer"
                                        src={baseurl + data?.coupon_icon}
                                        onError={(e: any) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "/assets/img/noImage.jpeg";
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="scale homeOffer"
                                        src="/assets/img/noImage.jpeg"
                                      />
                                    )
                                  ) : data?.sch_image !== null ? (
                                    <img
                                      className="scale homeOffer"
                                      src={baseurl + data?.sch_image}
                                      onError={(e: any) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "/assets/img/noImage.jpeg";
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="scale homeOffer"
                                      src="/assets/img/noImage.jpeg"
                                    />
                                  )}
                                </figure>
                                <figcaption className="content-box offerHeight">
                                  <a
                                    onClick={(e) => {
                                      data?.type == "Offers"
                                        ? opneModel(data?.id)
                                        : history.push(
                                            "/scholarships/" + data?.sch_slug
                                          );
                                    }}
                                    data-toggle="modal"
                                    data-target="#offer-pop"
                                    className="mt_btn_yellow"
                                  >
                                    view details
                                  </a>
                                  <h3>
                                    {data?.type == "Offers" ? "Get" : ""}
                                    {data?.type == "Offers" && (
                                      <span>
                                        {data?.coupon_type == 1
                                          ? data?.max_off + "%"
                                          : data?.max_off}{" "}
                                        OFF
                                      </span>
                                    )}
                                    {data?.type == "Offers"
                                      ? data?.coupon_title?.length > 16
                                        ? `${data?.coupon_title.substring(
                                            0,
                                            15
                                          )}.`
                                        : data?.coupon_title
                                      : data?.sch_title?.length > 40
                                      ? `${data?.sch_title.substring(0, 40)}.`
                                      : data?.sch_title}
                                  </h3>
                                </figcaption>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
                <div
                  className="all-center"
                  data-aos="fade-left"
                  // data-aos-duration="500"
                >
                  <Link to={"offers-and-scholarship"} className="view-more">
                    {" "}
                    View all
                  </Link>
                  {/* <a href="#" className="view-more">
                View all
              </a> */}
                </div>
              </div>
            </div>
          </section>

          <div
            id="offer-pop"
            className="modal fade offer_detailpopup"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal-tab-offer">
                <div className="modal-body p-0">
                  <div className="off-detail-item">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <figure className="feat-text">
                      {modelData[0]?.type == "Offers" ? (
                        modelData[0]?.coupon_icon !== null ? (
                          <img
                            className="scale homeOffer"
                            src={baseurl + modelData[0]?.coupon_icon}
                            onError={(e: any) => {
                              e.target.onerror = null;
                              e.target.src = "/assets/img/noImage.jpeg";
                            }}
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            className="scale homeOffer"
                            src="/assets/img/noImage.jpeg"
                          />
                        )
                      ) : modelData[0]?.sch_image !== null ? (
                        <img
                          className="scale homeOffer"
                          src={baseurl + modelData[0]?.sch_image}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/img/noImage.jpeg";
                          }}
                        />
                      ) : (
                        <img
                          className="scale homeOffer"
                          src="/assets/img/noImage.jpeg"
                        />
                      )}
                    </figure>
                    <figcaption className="content-box">
                      <h3>
                        {modelData[0]?.type == "Offers" ? "Get" : ""}
                        {modelData[0]?.type == "Offers" && (
                          <span>
                            {modelData[0]?.coupon_type == 1
                              ? modelData[0]?.max_off + "%"
                              : modelData[0]?.max_off}{" "}
                            OFF
                          </span>
                        )}
                        {modelData[0]?.type == "Offers"
                          ? modelData[0]?.coupon_title?.length > 16
                            ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                            : modelData[0]?.coupon_title
                          : modelData[0]?.sch_title?.length > 40
                          ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                          : modelData[0]?.sch_title}
                        {/* Study in Abroad */}
                      </h3>
                      <div className="bttns">
                        <a href="" className="tnc">
                          t&amp;c apply
                        </a>
                      </div>
                    </figcaption>
                    <figcaption className="details-box p-3">
                      <p className="text-left">
                        Coupon Code:{" "}
                        {modelData[0]?.type == "Offers" && (
                          <span>{modelData[0]?.coupon_code}</span>
                        )}{" "}
                      </p>
                      <p className="text-right">
                        exp:{" "}
                        {modelData[0]?.type == "Offers" && (
                          <span>
                            {moment(modelData[0]?.valid_from).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        )}
                        {modelData[0]?.type == "ScholarShip"
                          ? `${modelData[0]?.apply_end_date}.`
                          : ""}
                      </p>
                      <p className="text-left">
                        {/* {modelData[0]?.description} */}
                        {modelData[0]?.type == "Offers" && (
                          <span>{modelData[0]?.description}</span>
                        )}
                        {modelData[0]?.type == "ScholarShip"
                          ? `${modelData[0]?.short_desc}.`
                          : ""}
                      </p>
                      <div className="d-flex justify-content-end pt-2 mb-5 pb-1 px-2">
                        <SocialShareButton
                          urlsforShare={urlsforShare}
                          data={""}
                        />
                      </div>
                      <div className="bttns text-right">
                        <a
                          onClick={(e: any) =>
                            hendleApplyNow(e, modelData[0]?.id)
                          }
                          className={
                            hadleDisableApply(modelData[0]?.id)
                              ? "applybtn bggreadent disableApply"
                              : "applybtn bggreadent"
                          }
                        >
                          apply now
                        </a>
                      </div>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ScholarshipOffers;
