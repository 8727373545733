import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  applyNoAction,
  UniversityCourseAction,
  counselloerAction,
  UniversityAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import AdSlider from "../AdSlider/AdSlider";
import CourseFilter from "../coursefilter/CourseFilter";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { URL } from "../../redux/common/url";
import MenuOutSideClick from "../../layout/header/outsideClick";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { sorting } from "../../Utils/Common/Common";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";
function Program(props: any) {
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const history = useHistory();
  const uniSlug: any = useParams();
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const [course, setcourse] = useState<any[]>([]);
  const [courseid, setcourseid] = useState<any[]>([]);
  const [studylevel, setstudylevel] = useState<any>("1");
  const [sortBy, setosrtBy] = useState<any>();
  const baseurl = URL.API_BASE_URL;
  const id: any = useLocation();
  const [readMore, setreadMore] = useState<any>(5);
  const [search, setsearch] = useState<any[]>([]);
  const [searchRe, setsearchRe] = useState<any>("");
  const [Marks, setMarks] = useState<any>("");
  const [loadmore, setloadmore] = useState<any>(50);
  const [offset, setoffset] = useState<any>(0);
  const [CourseId, setCourseId] = useState<any[]>([]);
  const [SelectedCourseCount, setSelectedCourseCount] = useState<any[]>([]);
  const [userCourseLevel, setuserCourseLevel] = useState<any>("");
  const [oneTime, setOneTime] = useState<any>("0");
  const [universityCourseArr, setUniversityCourseArr] = useState<any[]>([]);
  const [CurrentLevel, setCurrentLevel] = useState<any>("");
  const [CurrentStreamId, setCurrentStreamId] = useState<any>("");
  const [universityCourse, setUniversityCourse] = useState<any>([]);
  const [indexMore, setIndexMore] = useState<any>("");
  /* filter from home variable*/
  const { setstudylevel_dt } = props;
  const [course_id_dt, setCourse_id_dt] = useState<any>(props?.course_id_dt);
  const [course_level_dt, setCourse_level_dt] = useState<any>(
    props?.course_level_dt
  );
  const isAuthenticated: any = getToken();
  const [countryOpne, setcountryOpne] = useState<any>(isMobile ? true : false);
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const university: any = useSelector((state: any) =>
    state.unversity.universityById ? state.unversity.universityById : []
  );

  const universityBySlug: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );

  const universitycourse: any = useSelector((state: any) =>
    state.universitycourse.universitycourse
      ? state.universitycourse.universitycourse
      : []
  );
  const appliedCourses: any = useSelector((state: any) =>
    state.unversity.appliedCoursesFrontendUniStud
      ? state.unversity.appliedCoursesFrontendUniStud
      : []
  );
  var appliedCoursesArray = [];
  if (appliedCourses.status) {
    appliedCourses?.data?.map((v: any, k: any) => {
      appliedCoursesArray.push(v.course_id);
    });
  }

  // console.log(JSON.stringify(universitycourse), "fdffffffffffffff");

  useEffect(() => {
    const sortedCourses = [...universitycourse].sort((a: any, b: any) => {
      // Ensure first_yr_fee is a valid number
      const feeA = a.first_yr_fee
        ? Number(a.first_yr_fee.replace(/[^\d.-]/g, "")) || 0
        : 0;
      const feeB = b.first_yr_fee
        ? Number(b.first_yr_fee.replace(/[^\d.-]/g, "")) || 0
        : 0;

      return sortBy === "asc" ? feeA - feeB : feeB - feeA;
    });

    setUniversityCourse(sortedCourses);
  }, [sortBy, universitycourse]);

  /* if (!isMobile)
    window.scroll(0, 750); */
  // useEffect(() => {
  //   var universitycourse_array = universityCourseArr;
  //   if (universitycourse_array.length > 0) {
  //     universitycourse_array.push(universitycourse);
  //     setUniversityCourseArr(universitycourse_array);
  //     console.log(1)
  //   } else {
  //     setUniversityCourseArr(universitycourse);
  //   }
  // }, [universitycourse]);
  const handleLogin = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    }
  };

  const openModelPopup = (event, id: any, level: any, streamId: any) => {
    setCurrentLevel(level);
    setCurrentStreamId(streamId);
    var maxChecked = $(".apply-now-checkbox").filter(":checked").length;
    var x = maxChecked + appliedCoursesArray.length;
    if (x > 3) {
      // toast.error('*you have already reached max selection of courses');
      maxChecked = x;
      // CourseId.push(appliedCoursesArray);
      setSelectedCourseCount(maxChecked);
    }
    if (maxChecked <= 3) {
      if ($("#_apply_now_" + id).prop("checked")) {
        CourseId.push(id);
        setSelectedCourseCount(maxChecked);
      } else {
        setSelectedCourseCount(maxChecked);
      }
    } else {
      $("#_apply_now_" + id).attr("checked", false);
      toast.error("*you have already reached max selection of courses.");
    }
    $("#__apply_now_" + id).click();
  };
  var userInterests = localStorage.getItem("userInterest");
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
  }

  const setEligibility = (marks: any, type = null) => {
    if (marks >= 85) {
      return setMarks(marks);
    } else if (marks < 85 && marks >= 70) {
      return setMarks(marks);
    } else {
      return setMarks(marks);
    }
  };
  $(".eligibility_btn").click(function (k, v) {
    var id = $(this).attr("data-id");
    $("#eligibility_" + id).fadeIn(0);
  });

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const activeCounselloer =
    counsellor &&
    counsellor?.filter((data: any) => {
      return data?.status == 1;
    });

  const allCourseUni_: any = useSelector((state: any) =>
    state.universitycourse.uniCourseName
      ? state.universitycourse.uniCourseName
      : []
  );

  const allCourseUni = allCourseUni_.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );
  useEffect(() => {
    if (id?.state?.university_id) {
      dispatch(
        UniversityAction.getUniversityById(
          id?.state?.university_id
            ? id?.state?.university_id
            : universityBySlug?.id
        )
      );
    }
  }, [id?.state?.university_id]);
  var IsAvailability = false;
  if (userInterests) {
    IsAvailability = true;
  }
  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);
  useEffect(() => {
    if (oneTime == 0) {
      setOneTime(1);
      if (userInterests) {
        setuserCourseLevel(userInterests[0]?.course_level);
        IsAvailability = true;
        if (university?.country_id && userInterests.length > 0) {
          userInterests[0]?.country_id?.map((data: any, i: any) => {
            if (data.countryId == university?.country_id) {
              IsAvailability = true;
            }
          });
        }
      }
    }
    dispatch(
      UniversityCourseAction.getUniCourses({
        UniversityId: id?.state?.university_id
          ? id?.state?.university_id
          : universityBySlug?.id,
        Offset: 0,
        Limit: 50,
      })
    );
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        UniversityId: id?.state?.university_id
          ? id?.state?.university_id
          : universityBySlug?.id,
        SearchTerm: search || searchRe,
        Offset: 0,
        Limit: 50,
        CourseId: course_id_dt ? [course_id_dt] : "",
      })
    );
    if (user?.id && user?.mobile) {
      dispatch(
        UniversityAction.appliedCoursesFrontendUniStudMain({
          university_id: id?.state?.university_id
            ? id?.state?.university_id
            : universityBySlug?.id,
          student_id: user?.id,
          mobile: user?.mobile,
        })
      );
    }
  }, [universityBySlug]);

  useEffect(() => {
    dispatch(counselloerAction.getAllCounsellor({}));
    return () => {};
  }, []);

  const hendleLodemore = (e: any) => {
    setloadmore(loadmore + 50);
    setoffset(offset + 50);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        UniversityId: id?.state?.university_id
          ? id?.state?.university_id
          : universityBySlug?.id,
        SearchTerm: "",
        Offset: offset + 50,
        Limit: 50,
      })
    );
    return () => {};
  };

  const sortByName = (e: any) => {
    setosrtBy(e);
  };

  const activeStream: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );
  const StreamName = activeStream.map((s) => s.stream_name);
  const filteredStream = activeStream.filter(
    ({ stream_name }, index) => !StreamName.includes(stream_name, index + 1)
  );

  function courseLevelType(level: any) {
    var isLevel = "";
    if (course_level_dt) {
      isLevel = course_level_dt;
    } else if (userCourseLevel) {
      isLevel = userCourseLevel;
    } else {
      isLevel = studylevel;
    }
    return isLevel == level;
  }

  const handleApplyNow = (event: any) => {
    var CourseIdFilter = CourseId.filter(function (a) {
      if (!this[a]) {
        this[a] = 1;
        return a;
      }
    }, {});
    if (CourseIdFilter.length > 0) {
      var postData = {
        student_id: user?.id,
        mobile: user?.mobile,
        university_id: id?.state?.university_id
          ? id?.state?.university_id
          : university?.id,
        course_level: userInterests
          ? userInterests[0]?.course_level
          : CurrentLevel,
        stream_id: userInterests
          ? userInterests[0]?.streamData?.streamId
          : CurrentStreamId,
        intake: userInterests ? userInterests[0]?.intake : null,
        course_id: CourseIdFilter,
        objective: userInterests ? userInterests[0].objective?.objective : null,
        country_id: university?.country_id,
        highest_edu: userInterests ? userInterests[0]?.highest_edu : null,
        tests: userInterests ? userInterests[0]?.tests : null,
      };
      dispatch(applyNoAction.applyNow(postData, history));

      dispatch(
        UniversityAction.appliedCoursesFrontendUniStudMain({
          university_id: id?.state?.university_id
            ? id?.state?.university_id
            : university?.id,
          student_id: user?.id,
          mobile: user?.mobile,
        })
      );
    } else {
      toast.error("Please choose at least 1 course.");
    }
  };

  const hendlSearchCourseByStream = (data: any) => {
    if (course_level_dt || course_id_dt) {
      setCourse_level_dt("");
      setCourse_id_dt("");
    }
    setsearch(data);
    setsearchRe(data);

    // dispatch(
    //   UniversityCourseAction.getAllUniCourses({
    //     UniversityId: id?.state?.university_id
    //       ? id?.state?.university_id
    //       : universityBySlug?.id,
    //     SearchTerm: data ? data : "",
    //     Limit: 50,
    //     CourseId: course_id_dt ? [course_id_dt] : "",
    //   })
    // );
  };

  const uniAndCouresHendle = (e: any) => {
    setsearchRe(e);
    setsearch(e);
    // dispatch(
    //   UniversityCourseAction.getAllUniCourses({
    //     UniversityId: id?.state?.university_id
    //       ? id?.state?.university_id
    //       : universityBySlug?.id,
    //     SearchTerm: e,
    //     CourseId: arrayid,
    //     Offset: 0,
    //     Limit: 50,
    //   })
    // );
    return () => {};
  };

  const searchUni = () => {
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        UniversityId: id?.state?.university_id
          ? id?.state?.university_id
          : universityBySlug?.id,
        SearchTerm: search || searchRe,
        Offset: 0,
        Limit: 50,
        CourseId: course_id_dt ? [course_id_dt] : "",
      })
    );
  };

  const levelofstudy = (e: any) => {
    setstudylevel(e);
    setstudylevel_dt(e);
    setcourseid([]);
    setcourse([]);
    if (userInterests) setuserCourseLevel(e);
  };

  const hendlClickCourse = (event: any) => {
    if (course_level_dt || course_id_dt) {
      setCourse_level_dt("");
      setCourse_id_dt("");
    }
    let array = [...course];
    let arrayid = [...courseid];
    if (event.target.checked) {
      arrayid.push(event.target.value);
      array.push(event.target.name);
      setcourseid(arrayid);
      setcourse(array);
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid,
          UniversityId: id?.state?.university_id
            ? id?.state?.university_id
            : universityBySlug?.id,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );
    } else {
      let coun = array.indexOf(event.target.name);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcourseid(arrayid);
        setcourse(array);

        dispatch(
          UniversityCourseAction.getAllUniCourses({
            CourseId: arrayid,
            UniversityId: id?.state?.university_id
              ? id?.state?.university_id
              : universityBySlug?.id,
            SearchTerm: "",
            Offset: 0,
            Limit: 50,
          })
        );
      }
    }
  };
  const removeCourse = (event: any, value: any) => {
    if (course_level_dt || course_id_dt) {
      setCourse_level_dt("");
      setCourse_id_dt("");
    }
    let array = [...course];
    let arrayid = [...courseid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcourse(array);
      setcourseid(arrayid);

      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid,
          UniversityId: id?.state?.university_id
            ? id?.state?.university_id
            : universityBySlug?.id,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );
    }
  };

  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };
  var i = 1;
  return (
    <div>
      <ScrollToTop />
      <section className="uni-list-page" id="program">
        <div className="container">
          <div className="program-search shade1">
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    ref={anchorRef1}
                    type="text"
                    className="form-control"
                    placeholder="search stream and course"
                    value={searchRe}
                    onChange={(e) => {
                      uniAndCouresHendle(e.target.value);
                    }}
                  />
                  {anchor1 && (
                    <div
                      className="searchuniversitiesandcourses"
                      style={{ padding: "0px", width: "20%" }}
                    >
                      <ul>
                        {filteredStream &&
                          filteredStream
                            .filter((data: any) => {
                              return (
                                searchRe === "" ||
                                data?.stream_name
                                  .toLowerCase()
                                  .includes(searchRe?.toLowerCase())
                              );
                            })
                            ?.map((data: any, i: any) => {
                              return (
                                i < 15 && (
                                  <li
                                    onClick={() => {
                                      hendlSearchCourseByStream(
                                        data?.stream_name
                                      );
                                    }}
                                    key={i}
                                  >
                                    {data?.stream_name}
                                  </li>
                                )
                              );
                            })}
                      </ul>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button
                      onClick={searchUni}
                      className="btn hover-overlay  btn-secondary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="serchsuggest">
                  <p>search by</p>
                  <ul>
                    {filteredStream &&
                      filteredStream?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <a
                              onClick={(e: any) =>
                                hendlSearchCourseByStream(data?.stream_name)
                              }
                            >
                              {data?.stream_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 col-md-pull-10">
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <h5>
                    <span>
                      selected <b>filters</b> <i className="fa fa-angle-up"></i>
                    </span>{" "}
                  </h5>
                  <ul className="clear-filter">
                    {course.map((data, index) => {
                      return (
                        <li key={index}>
                          {data}
                          <a onClick={(e: any) => removeCourse(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span onClick={hendlecountryOpne}>
                      filter by <b>course</b>
                      {countryOpne == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={countryOpne == true ? "countryHide" : ""}>
                    {countryOpne == false &&
                      allCourseUni &&
                      allCourseUni?.map((data: any, i: any) => {
                        if (
                          (userCourseLevel ? userCourseLevel : studylevel) ==
                          data?.course_level
                        ) {
                          return (
                            <li key={i}>
                              <label>
                                <input
                                  className="vertical_align_checkbox"
                                  type="checkbox"
                                  name={data?.title}
                                  value={data?.course_id}
                                  checked={courseid.includes(
                                    data?.course_id
                                      ? data?.course_id.toString()
                                      : null
                                  )}
                                  onChange={(e: any) => hendlClickCourse(e)}
                                />
                                <i className="awe-icon awe-icon-check"></i>
                                <span className="vertical_align">
                                  {data?.title?.length > 20
                                    ? `${data?.title.substring(0, 20)}...`
                                    : data?.title}
                                </span>
                              </label>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>

                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">counsellor </h5>
                  <ul className="bg-white">
                    {activeCounselloer &&
                      activeCounselloer?.map((data: any, i: any) => {
                        return (
                          i < 6 && (
                            <li key={i}>
                              <div className="counsellorBox">
                                <div
                                  className="relatedCounsellor"
                                  style={{
                                    background: `url(${
                                      baseurl + data?.profile_image
                                    })`,
                                  }}
                                ></div>
                              </div>

                              {/* <img src="assets/img/testi.png" alt="" /> */}
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    history.push("/counsellor/" + data?.slug);
                                  }}
                                >
                                  {data?.gender == 1 ? "Mr." : "Ms. "}{" "}
                                  {data?.name}{" "}
                                </h6>
                                <div className="feat-inn discussiondetails">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                </div>
                                <p>
                                  <small>
                                    {data?.counselled > 500
                                      ? "500+"
                                      : data?.counselled}{" "}
                                    student counselled
                                  </small>
                                </p>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
                <div className="banner-section1">
                  <VerticalAddSlider />
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2" id="courses_list">
              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  {/* <!-- ITEM --> */}
                  <div className="course-offer-uni shade1 pb-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="headingmains text-center">
                          <h4 className="titlewithline">
                            Course offered by{" "}
                            {universityBySlug?.university_name
                              ? universityBySlug?.university_name + " "
                              : ""}
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33.333"
                                height="33.667"
                                viewBox="0 0 33.333 33.667"
                              >
                                <path
                                  id="Icon_material-local-offer"
                                  data-name="Icon material-local-offer"
                                  d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                                  transform="translate(-3 -3)"
                                  fill="#fbb415"
                                ></path>
                              </svg>
                            </span>
                          </h4>
                        </div>
                        <div className="all-center sub-section pb-0">
                          <div className="row">
                            <div className="col-md-9">
                              <div className="lavelbox text-left">
                                <b>level of study</b>
                                {(() => {
                                  return (
                                    <>
                                      {" "}
                                      <input
                                        type="radio"
                                        name="RadioGroup1"
                                        value="1"
                                        onClick={(e: any) => {
                                          levelofstudy(e.target.value);
                                          setosrtBy("");
                                          if (course_level_dt) {
                                            setCourse_level_dt(1);
                                          }
                                        }}
                                        id="RadioGroup1_0"
                                        defaultChecked={
                                          course_level_dt == 1
                                            ? true
                                            : !course_level_dt &&
                                              userCourseLevel
                                            ? !course_level_dt &&
                                              userCourseLevel == 1
                                              ? true
                                              : false
                                            : true
                                        }
                                        style={
                                          {
                                            // display: userCourseLevel
                                            //   ? userCourseLevel == 1
                                            //     ? "none"
                                            //     : "none"
                                            //   : "inline-block",
                                          }
                                        }
                                      />
                                      <label
                                        htmlFor="RadioGroup1_0"
                                        style={
                                          {
                                            // display: userCourseLevel
                                            //   ? userCourseLevel == 1
                                            //     ? "inline-block"
                                            //     : "none"
                                            //   : "inline-block",
                                          }
                                        }
                                      >
                                        {" "}
                                        bachelor's{" "}
                                      </label>
                                    </>
                                  );
                                })()}

                                {(() => {
                                  return (
                                    <>
                                      {" "}
                                      <input
                                        type="radio"
                                        name="RadioGroup1"
                                        value="2"
                                        onClick={(e: any) => {
                                          levelofstudy(e.target.value);
                                          setosrtBy("");
                                          if (course_level_dt) {
                                            setCourse_level_dt(2);
                                          }
                                        }}
                                        id="RadioGroup1_1"
                                        defaultChecked={
                                          course_level_dt == 2
                                            ? true
                                            : !course_level_dt &&
                                              userCourseLevel == 2
                                            ? true
                                            : false
                                        }
                                        style={
                                          {
                                            // display: userCourseLevel
                                            //   ? userCourseLevel == 2
                                            //     ? "none"
                                            //     : "none"
                                            //   : "inline-block",
                                          }
                                        }
                                      />
                                      <label
                                        htmlFor="RadioGroup1_1"
                                        style={
                                          {
                                            // display: userCourseLevel
                                            //   ? userCourseLevel == 2
                                            //     ? "inline-block"
                                            //     : "none"
                                            //   : "inline-block",
                                          }
                                        }
                                      >
                                        {" "}
                                        master's
                                      </label>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="page-top pull-right mb-4">
                                <select
                                  className="awe-select"
                                  onChange={(e: any) =>
                                    sortByName(e.target.value)
                                  }
                                  value={sortBy}
                                >
                                  <option value={""}>sort by</option>
                                  <option value="asc">lowest fees</option>
                                  <option value="desc">highest fees</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {(universityCourse &&
                              universityCourse?.length == 0) ||
                            (universityCourse.filter(
                              (item: any) => item?.course_level == 2
                            ).length == 0 &&
                              courseLevelType(2)) ||
                            (universityCourse.filter(
                              (item: any) => item?.course_level == 1
                            ).length == 0 &&
                              courseLevelType(1)) ? (
                              <div className="nodataFound">
                                <img
                                  src="/assets/img/noDataFound.jpeg"
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {universityCourse?.map((data: any, index: any) => {
                              let date =
                                data?.application_last_date != "0000-00-00" &&
                                data?.application_last_date != null &&
                                data?.application_last_date != "Invalid date"
                                  ? dateFormat(
                                      data?.application_last_date,
                                      "d-mmm-yyyy"
                                    )
                                  : "N/A";
                              // const exam = JSON.parse(data?.exam_score);
                              if (course_level_dt) {
                                var isLevel = course_level_dt;
                              } else if (userCourseLevel) {
                                var isLevel = userCourseLevel;
                              } else {
                                var isLevel = studylevel;
                              }

                              return (
                                isLevel == data?.course_level && (
                                  <>
                                    {}
                                    <div
                                      course_level={data?.course_level}
                                      className="col-md-6"
                                      key={index}
                                    >
                                      <div className="studywrp shade1">
                                        <div className="bgstudy">
                                          <div className="row ">
                                            <div className="col-sm-12 col-lg-7 text-left">
                                              <h6>
                                                {data?.university_course} <br />
                                                {data?.course_type == 1
                                                  ? "full time"
                                                  : data?.course_type == 2
                                                  ? "part time"
                                                  : data?.course_type == 3
                                                  ? "weekend"
                                                  : data?.course_type == 4
                                                  ? "other"
                                                  : ""}
                                              </h6>
                                            </div>
                                            <div className="col-sm-12 col-lg-5">
                                              <h5>
                                                <span>
                                                  {data?.fee_currency_code}
                                                </span>{" "}
                                                {data?.first_yr_fee}/year
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="detailstudy">
                                          <div className="row">
                                            <div className="col-sm-12 col-lg-12 text-left">
                                              <table
                                                width="100%"
                                                style={{ border: "0" }}
                                                cellSpacing="0"
                                                cellPadding="0"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <table
                                                        width="100%"
                                                        style={{ border: "0" }}
                                                        cellSpacing="0"
                                                        cellPadding="0"
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td rowSpan={2}>
                                                              <img
                                                                src="/assets/img/time.png"
                                                                alt=""
                                                                className=""
                                                              />
                                                            </td>
                                                            <td>
                                                              <b>
                                                                {data?.duration}
                                                              </b>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              <span>
                                                                course&nbsp;duration
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td>
                                                      <table
                                                        width="100%"
                                                        style={{ border: "0" }}
                                                        cellSpacing="0"
                                                        cellPadding="0"
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td rowSpan={2}>
                                                              <img
                                                                src="/assets/img/date.png"
                                                                alt=""
                                                                className=""
                                                              />
                                                            </td>
                                                            <td>
                                                              <b>{date}</b>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              <span>
                                                                application&nbsp;deadline
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td>
                                                      <table
                                                        width="100%"
                                                        style={{ border: "0" }}
                                                        cellSpacing="0"
                                                        cellPadding="0"
                                                      >
                                                        <tbody className="course__">
                                                          <tr>
                                                            <td rowSpan={2}>
                                                              <img
                                                                src="/assets/img/library-building.png"
                                                                alt=""
                                                                className=""
                                                              />
                                                            </td>
                                                            <td>
                                                              {data?.entry_requirements ? (
                                                                indexMore ===
                                                                  data?.id &&
                                                                data?.entry_requirements?.split(
                                                                  " "
                                                                ).length <
                                                                  readMore ? (
                                                                  <p className="course__font">
                                                                    {
                                                                      data?.entry_requirements
                                                                    }
                                                                  </p>
                                                                ) : (
                                                                  <p className="course__font">
                                                                    {" "}
                                                                    {data?.entry_requirements
                                                                      ?.split(
                                                                        " "
                                                                      )
                                                                      .splice(
                                                                        0,
                                                                        5
                                                                      )
                                                                      .join(
                                                                        " "
                                                                      )}
                                                                    .{" "}
                                                                    <a
                                                                      className="read-more-new"
                                                                      href="#"
                                                                      onClick={(
                                                                        e: any
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setreadMore(
                                                                          data?.entry_requirements?.split(
                                                                            " "
                                                                          )
                                                                            .length &&
                                                                            data?.entry_requirements?.split(
                                                                              " "
                                                                            )
                                                                              .length +
                                                                              1
                                                                        );
                                                                        setIndexMore(
                                                                          data?.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      read more
                                                                    </a>
                                                                  </p>
                                                                )
                                                              ) : (
                                                                <p></p>
                                                              )}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              <span>
                                                                entry&nbsp;requirement
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div className="examscore">
                                                exam scores &nbsp; IELTS{" "}
                                                <b>
                                                  {data?.iselts_score
                                                    ? data?.iselts_score
                                                    : 0}
                                                </b>{" "}
                                                | TOEFL{" "}
                                                <b>
                                                  {data?.toefl_scrore
                                                    ? data?.toefl_scrore
                                                    : 0}
                                                </b>{" "}
                                                | PTE{" "}
                                                <b>
                                                  {data?.pte_score
                                                    ? data?.pte_score
                                                    : 0}
                                                </b>
                                              </div>

                                              <div className="row">
                                                <div className="col-6">
                                                  <div className="option">
                                                    <span>
                                                      options available
                                                    </span>
                                                    M.B.A Entrepreneurship
                                                  </div>
                                                </div>
                                                <div className="col-6">
                                                  {(() => {
                                                    var obtainMarks: any =
                                                      userInterests
                                                        ? userInterests[0]
                                                            ?.highest_edu
                                                            ?.Achieved_Or_Expected_marks
                                                        : 0;
                                                    var resultType =
                                                      userInterests
                                                        ? userInterests[0]
                                                            ?.highest_edu
                                                            ?.resultType
                                                        : null;
                                                    if (resultType == "CGPA") {
                                                      obtainMarks =
                                                        obtainMarks * 10;
                                                    }
                                                    if (resultType == "Grade") {
                                                      if (
                                                        obtainMarks.toLowerCase() ==
                                                        "a"
                                                      ) {
                                                        obtainMarks = 85;
                                                      } else if (
                                                        obtainMarks.toLowerCase() ==
                                                        "b"
                                                      ) {
                                                        obtainMarks = 75;
                                                      } else if (
                                                        obtainMarks.toLowerCase() ==
                                                        "c"
                                                      ) {
                                                        obtainMarks = 65;
                                                      } else if (
                                                        obtainMarks.toLowerCase() ==
                                                        "d"
                                                      ) {
                                                        obtainMarks = 55;
                                                      }
                                                    }
                                                    if (
                                                      IsAvailability == true
                                                    ) {
                                                      return (
                                                        <div className="option text-right">
                                                          <a
                                                            href="javascript:void(0)"
                                                            className="eligibility_btn"
                                                          >
                                                            eligibility :{" "}
                                                            <b>
                                                              {obtainMarks >=
                                                              85 ? (
                                                                <strong
                                                                  style={{
                                                                    color:
                                                                      "green",
                                                                  }}
                                                                >
                                                                  high
                                                                </strong>
                                                              ) : obtainMarks >=
                                                                  70 &&
                                                                obtainMarks <
                                                                  85 ? (
                                                                <strong
                                                                  style={{
                                                                    color:
                                                                      "orange",
                                                                  }}
                                                                >
                                                                  moderate
                                                                </strong>
                                                              ) : (
                                                                <strong
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  low
                                                                </strong>
                                                              )}
                                                            </b>
                                                          </a>
                                                        </div>
                                                      );
                                                    } else {
                                                      return (
                                                        <div className="option text-right">
                                                          {(() => {
                                                            if (user?.id) {
                                                              return (
                                                                <a
                                                                  href="/applynow"
                                                                  className="checkbtn eligibility_btn"
                                                                >
                                                                  check
                                                                  eligibility{" "}
                                                                </a>
                                                              );
                                                            } else {
                                                              return (
                                                                <a
                                                                  onClick={
                                                                    handleLogin
                                                                  }
                                                                  className="checkbtn eligibility_btn"
                                                                >
                                                                  check
                                                                  eligibility{" "}
                                                                </a>
                                                              );
                                                            }
                                                          })()}
                                                        </div>
                                                      );
                                                    }
                                                  })()}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6">
                                                  <div className="option">
                                                    Offered by{" "}
                                                    <p className="color-green">
                                                      {
                                                        data?.ad_university
                                                          ?.university_name
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                                {(() => {
                                                  if (IsAvailability == true) {
                                                    return (
                                                      <div className="col-6">
                                                        <div className="option text-right">
                                                          <div
                                                            style={{
                                                              marginTop: "5px",
                                                            }}
                                                          >
                                                            <div
                                                              id={
                                                                "__apply_now_" +
                                                                data?.id
                                                              }
                                                              className="hide"
                                                              data-toggle="modal"
                                                              data-target="#date_foo"
                                                            ></div>
                                                            <input
                                                              onClick={(e) =>
                                                                openModelPopup(
                                                                  e,
                                                                  data?.id,
                                                                  data?.course_level,
                                                                  data?.stream_id
                                                                )
                                                              }
                                                              className="apply-now-checkbox"
                                                              style={{
                                                                display:
                                                                  appliedCoursesArray.includes(
                                                                    data?.id
                                                                  )
                                                                    ? "none"
                                                                    : "",
                                                              }}
                                                              type="checkbox"
                                                              id={
                                                                "_apply_now_" +
                                                                data?.id
                                                              }
                                                              disabled={
                                                                appliedCoursesArray.includes(
                                                                  data?.id
                                                                ) ||
                                                                appliedCoursesArray.length >=
                                                                  3
                                                              }
                                                            />
                                                            <label
                                                              className="apply-now-label"
                                                              for={
                                                                "_apply_now_" +
                                                                data?.id
                                                              }
                                                            >
                                                              {appliedCoursesArray.includes(
                                                                data?.id
                                                              )
                                                                ? "Applied"
                                                                : "Apply Now"}
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                              {/* <a href="" className="checkbtn">
                                            check eligibility
                                          </a> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {(() => {
                                      if (i % 6 === 0) {
                                        i = 1;
                                        return (
                                          <div className="col-md-12">
                                            <AdSlider />
                                          </div>
                                        );
                                      } else {
                                        i++;
                                      }
                                    })()}
                                  </>
                                )
                              );
                            })}

                            {/* <div className="col-12">
                              <AdSlider />
                            </div> */}
                            <div className="col-md-12 text-center">
                              {!(
                                (universityCourse &&
                                  universityCourse?.length < 50) ||
                                (universityCourse.filter(
                                  (item: any) => item?.course_level == 2
                                ).length == 0 &&
                                  courseLevelType(2)) ||
                                (universityCourse.filter(
                                  (item: any) => item?.course_level == 1
                                ).length == 0 &&
                                  courseLevelType(1))
                              ) ? (
                                <div className="all-center my-2">
                                  <a
                                    onClick={(e) => {
                                      hendleLodemore(e);
                                    }}
                                    className="view-more readbtn"
                                  >
                                    Load More
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- END / ITEM --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      Apply eligibility popup
      */}
      <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="row app_now_">
                <div className="col-lg-12">
                  <p className="app_now_head">
                    Are you sure want to apply for this course?
                  </p>
                  <p className="app_max_">*You can choose any 3 courses.</p>
                  <p className="">
                    You have selected{" "}
                    {SelectedCourseCount ? SelectedCourseCount : 0} out of 3
                    courses.
                  </p>
                </div>
              </div>
              <figcaption className="details-box pb-3 pt-0">
                <div className="bttns text-center">
                  {(() => {
                    if (SelectedCourseCount > 0) {
                      return (
                        <a className="applybtn bggreadent">
                          <span
                            className="btn btn-success font-weight-bold _submit_btn"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e: any) => handleApplyNow(e)}
                          >
                            apply now
                          </span>
                        </a>
                      );
                    }
                  })()}
                  {(() => {
                    if (SelectedCourseCount < 3) {
                      return (
                        <span
                          className="font-weight-bold _app_add_m"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          + add more
                        </span>
                      );
                    } else {
                      return (
                        <p className="app_max_ pt-2">
                          *you have already reached max selection of courses
                        </p>
                      );
                    }
                  })()}
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Program;
