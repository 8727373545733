import { verify } from "crypto";

export const URL = {
  ADMIN_BASE_URL: "https://admin.admisify.com",
  // API_BASE_URL: "http://3.7.14.237:8081",
  API_BASE_URL: "https://apis.admisify.com",
  // API_BASE_URL: "http://localhost:8081",
  getDiscussionList: "/api/discussions/getDiscussionslist",
  getDiscussion: "/api/discussions/getDiscussion",
  getdiscommentlist: "/api/discomment/frontend/list",
  discommentAdd: "/api/discomment/add",
  getDisCategoryList: "/api/category/getCategoryList",
  createUser: "/api/users/add",
  login: "/api/signin",
  forgetPassword: "/api/student/forgot/password",
  studentSignup: "/api/student/signup",
  studentVerfiyOtp: "/api/student/verfiyOtp",
  studentCheckEmailExist: "/api/student/checkEmailExist",
  studentCheckMobileExist: "/api/student/checkMobileExist",
  studentUpdate: "/api/student/update",
  getStudentById: "/api/student/getStudentById",
  getAllUniversity: "/api/University/getAllUniversity",
  getuniversities: "/api/university/v1/getuniversities",
  trendingUniversities: "/api/university/frontend/trendingUniversities",
  fineUniversityBySlug: "/api/university/fineUniversityBySlug",
  getrankinguniversities: "/api/university/getrankinguniversities",
  getfaqcategory: "/api/faqcategory/getfaqcategory",
  getfaqbyid: "/api/faq/getfaqbyid",
  getfaqlist: "/api/faq/getfaqlist",
  getAllCountry: "/api/country/getAllCountry",
  getCountryBySlug: "/api/country/getCountryBySlug",
  // getDiscussionsbyId: "/api/discussions/getDiscussionsbyId",
  getDiscussionsbyId: "/api/discussions/frontend/getdiscussionsbyid",
  getUniversityById: "/api/University/getUniversityById",
  getAllUniversitiesComment: "/api/University/getAllUniversitiesComment",
  addUniversityComment: "/api/University/addUniversityComment",
  // getAllUniCourses: "/api/UniversityCourse/getAllCourses",
  getAllUniCourses: "/api/universityCourse/v1/frontend/courses",
  getAllCourses: "/api/Course/getAllCourses",
  getCourseDetails: "/api/Course/frontend/details",
  getSimilarcourse: "/api/universityCourse/v2/frontend/courses",
  getCourseBySlug: "/api/Course/frontend/details",
  gettestimoniallist: "/api/testimonial/gettestimoniallist",
  getPackagelist: "/api/package/getPackagelist",
  // getAllCounsellor: "/api/Counsellor/getAllCounsellor",
  getAllCounsellor: "api/counsellor/getCounsellorInfo",
  getCounsellorInfo: "/api/counsellor/getCounsellorInfo",
  getcoachinfo: "/api/coach/getcoachinfo",
  getAllcoach: "/api/coach/getAllcoach",
  getCoachbyId: "/api/coach/getCoachbyId",
  getCoachBySlug: "/api/coach/getCoachBySlug",
  getCounsellorbyId: "/api/counsellor/getCounsellorbyId",
  getCounsellorBySlug: "/api/counsellor/getCounsellorBySlug",
  getcoachcounsellor: "/api/coach/getcoachcounsellor",
  getofficelist: "/api/office/getofficelist",
  getofficebyid: "/api/office/getofficebyid",
  getofficebyslug: "/api/office/getofficebyslug",
  getarticlelist: "/api/blog/v1/frontend/blogsList",
  getreviewlist: "/api/testimonial/backend/reviews",
  getarticlebyid: "/api/article/getarticlebyid",
  getAllCity: "/api/city/getAllCity",
  getCityById: "/api/city/getCityById",
  getAllState: "/api/state/getAllState",
  getstreamlist: "/api/stream/getstreamlist",
  // applicantAdd: "/api/applicant/add",
  applicantAdd: "/api/student/frontend/update/interest",
  getbloglist: "/api/blog/getbloglist",
  getblogbyid: "/api/blog/getblogbyid",
  getBlogCategory: "/api/blogcategory/getalllist",
  blogupdatelikecommentcount: "/api/blog/updatelikecommentcount",
  blogLikeUnlike: "/api/blog/blogLikeUnlike",
  getUserActionForBlogDetails: "/api/blog/getUserActionBlog",
  blogBySlug: "/api/blog/findBySlug",
  blogCommentAdd: "/api/blogComment/add",
  blogCommentFindAll: "/api/blogComment/findAll",
  getallapplicant: "/api/applicant/frontend/profile/applied/courses",
  getAllCoupon: "/api/coupon/getAllCoupon",
  scholarshipGetAllDetails: "/api/scholarship/getAllDetails",
  fineByScholarSlug: "/api/scholarship/fineByScholarSlug",
  getOffersAndScholar: "/api/scholarship/getOffersAndScholar",
  getSetting: "/api/setting/getSetting",
  addGetInTouch: "/api/support/frontend/get-in-touch",
  applyJob: "/api/jobapplicant/frontend/submit-job-application",
  jobList: "/api/jobs/frontend/list",
  uploadFile: "/api/fileUpload/upload",
  blogsCategories: "/api/blog/frontend/blogsCategories",
  blogsList: "/api/blog/frontend/blogsList",
  countryCourse: "/api/university/frontend/universitiesStreamCountryWise",
  examList: "/api/exams/frontend/allExamList",
  filterCourses: "/api/universityCourse/frontend/filterCourses",
  relatedDiscussion: "/api/discussions/frontend/related/discussion",
  trendingDiscussion: "/api/discussions/frontend/trending/discussion",
  vBLog: "/api/vblog/backend/list",
  discussionLikeUnlike: "/api/discussions/discussionLikeUnlike",
  discussionCommentLikeUnlike: "/api/discomment/commentLikeUnlike",
  serviceRequest: "/api/services/frontend/submit-service-request",
  uploadAttachment: "/api/studentAttachment/add",
  addQueryAll: "/api/enquiry/add",
  attachmentList: "/api/studentAttachment/getAllList",
  loginWithOtp: "/api/student/frontend/loginWithOtp",
  deleteAttachments: "/api/student/frontend/delete-attachment",
  verifyUserEmail: "api/student/frontend/verify/email",
  verificationOfEmail: "api/student/email-verification-token",
  universityLikeDislike: "/api/student/frontend/like-unlike",
  universityLikeList: "/api/student/frontend/universities/liked",
  shortListUniversity: "/api/student/frontend/universities/shortlisted",
  shortListCourses: "/api/student/v3/shortlisted-courses",
  studentDiscussion: "/api/student/frontend/discussion/list",
  universityReviewList: "/api/student/frontend/university/reviews",
  serviceList: "/api/student/frontend/service/requests",
  bookAppointment: "/api/appointment/frontend/create/appointment",
  myAppointment: "/api/appointment/frontend/appointment/list",
  applyNow: "/api/applicant/frontend/apply-now",
  coachCounsellorForProfile:
    "/api/appointment/frontend/appoint/coach-counsellor",
  addDiscussion: "/api/discussions/add",
  appliedCoursesFrontendUniStud: "/api/applicant/frontend/applied/courses",
  studentAddReview: "/api/testimonial/frontend/add",
  getStudentReview: "/api/testimonial/frontend/list",
  updatePassword: "/api/student/frontend/update/password",
  loginWithPassword: "/api/student/loginwithpassword",
  paymentReturn: "/api/payment/appointment/return",
  paymentHistory: "/api/student/frontend/payments/history",
  getAdBanner: "/api/addmanagement/getAddmanagementList",
  // seoDetails: "/api/pagemeta/frontend/seo-details",
  seoDetails: "/api/pagemeta/v2/frontend/detailsBySlug",
  applyScholar: "/api/scholarship/apply",
  getAppliedScholar: "/api/scholarship/getapplyscholar",
  PayRedirect: "/api/payment/V1/pay",
  applicationList: "/api/applications/list",
};
