import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UniversityAction,
  studentAction,
  applyNoAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import StarRatings from "react-star-ratings";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
// import Swal from "sweetalert2"

function Shortlist() {
  const [shortlist, setshortlist] = useState<any>("self-shortlisted");
  const [indexMore, setIndexMore] = useState<any>("");
  const [readMore, setreadMore] = useState<any>(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modelData, setmodelData] = useState<any>("");
  const [CourseId, setCourseId] = useState<any[]>([]);

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const shortListedUniversity: any = useSelector((state: any) =>
    state.student.shortListUniversity ? state.student.shortListUniversity : []
  );
  const getshortListedCourse: any = useSelector((state: any) =>
    state.student.shortListCourses ? state.student.shortListCourses : []
  );
  const uniLikeUnlike: any = useSelector((state: any) =>
    state.unversity.uniLikeDislike ? state.unversity.uniLikeDislike : []
  );
  const university: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );
  const filteraplicant_: any = useSelector((state: any) =>
    state.applyNow.getAllaplicant ? state.applyNow.getApplicationList : []
  );

  // let appliedCoursesArray = [];

  // // Populate the appliedCoursesArray if filteraplicant_.status is true
  // if (filteraplicant_.status) {
  //     filteraplicant_?.data?.forEach((v: any) => {
  //         appliedCoursesArray.push(v.course_id);
  //         console.log("pusghdc", v.course_id)
  //     });
  // }

  const baseurl = URL.API_BASE_URL;

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  const hendleLike = (e: any) => {
    // alert('huh')
    var Data = {
      id: user?.id,
      // id: null,
      student_id: user?.id,
      university_id: e,
      mobile: user?.mobile,
    };
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      dispatch(UniversityAction.universityLikeDislike(Data, user?.id));
    }
  };

  $(".track-appli").click(function (k, v) {
    var id = $(this).attr("data-id");
    console.log(id);
    $("#track-applicants-" + id).fadeIn();
  });

  useEffect(() => {
    var Data = { id: user?.id, mobile: user?.mobile };
    dispatch(studentAction.shortListUniversity(Data, user?.id));
    return () => {};
  }, [uniLikeUnlike]);

  useEffect(() => {
    if (shortlist === "suggested") {
      var Data = { id: user?.id, mobile: user?.mobile };
      dispatch(studentAction.shortListCourses(Data, user?.id));
      return () => {};
    }
  }, [shortlist]);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);

  const coun = (e: any, data: any) => {
    setshortlist(data.data);
  };
  var userInterests = localStorage.getItem("userInterest");
  var IsAvailability = false;
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
  }
  if (userInterests) {
    IsAvailability = true;
  }
  const handleApplyNow = (event: any) => {
    // console.log(event.target.className, "---");

    if (event.target.className === "applied-label") {
      return history.push("/profile/applications");
    }

    const courseIndex = event.currentTarget.getAttribute("data-course-index");
    const selectedCourse = getshortListedCourse[courseIndex];
    CourseId.push(selectedCourse.ShortlistedUniversityCourse.id);
    var CourseIdFilter = CourseId.filter(function (a) {
      if (!this[a]) {
        this[a] = 1;
        return a;
      }
    }, {});

    if (CourseIdFilter.length > 0) {
      var postData = {
        id: selectedCourse.id,
        student_id: user?.id,
        mobile: user?.mobile,
        university_id: selectedCourse.ShortlistedUniversity.id,
        course_level: selectedCourse.ShortlistedUniversityCourse.course_level,
        stream_id: selectedCourse.ShortlistedUniversityCourse.stream_id,
        intake: selectedCourse.ShortlistedUniversity.intake,
        course_id: CourseIdFilter,
        objective: userInterests ? userInterests[0].objective?.objective : null,
        country_id: selectedCourse.ShortlistedUniversityCourse.country_id,
        highest_edu: userInterests ? userInterests[0]?.highest_edu : null,
        tests: userInterests ? userInterests[0]?.tests : null,
      };

      dispatch(applyNoAction.applyNow(postData, history));

      // if (user?.id && user?.mobile) {
      //   dispatch(UniversityAction.appliedCoursesFrontendUniStudMain({
      //     university_id: selectedCourse.ShortlistedUniversity.id,
      //     student_id: user?.id,
      //     mobile: user?.mobile,
      //   }));
      // }
    }
  };

  return (
    <div className="col-md-12 col-lg-9">
      <div className="row">
        <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
          <div className="page-sidebar">
            {getOFferAndScholar &&
              getOFferAndScholar?.map((data: any, i: any) => {
                return (
                  i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a
                            onClick={(e) => {
                              data?.type == "Offers"
                                ? opneModel(data?.id)
                                : history.push(
                                    "/scholarships/" + data?.sch_slug
                                  );
                            }}
                            data-toggle="modal"
                            data-target="#offer-pop"
                          >
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a
                              className="margin_auto"
                              onClick={(e) => {
                                data?.type == "Offers"
                                  ? opneModel(data?.id)
                                  : history.push(
                                      "/scholarships/" + data?.sch_slug
                                    );
                              }}
                              data-toggle="modal"
                              data-target="#offer-pop"
                            >
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? "Get " + data?.max_off + "%"
                                      : "Get " + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                  ? `${data?.sch_title.substring(0, 40)}.`
                                  : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="contentbx shade1 bg-white application_wrap h-100">
            <section className="categories">
              <div className="col-md-12 col-lg-12">
                <div className="appli-t">
                  <h1>shortlisted</h1>
                  <img src="/assets/img/appoint--icon.svg" />
                </div>
                <div className="col-lg-12 col-md-push-2 mb-4">
                  <div className="row">
                    <div className="col-md-12 skew-tabs-shortlist">
                      <ul>
                        <li
                          className={
                            shortlist == "self-shortlisted" ? "active" : ""
                          }
                          onClick={(e) => {
                            coun(e, { data: "self-shortlisted" });
                          }}
                        >
                          <a>self shortlisted</a>
                        </li>
                        <li
                          className={shortlist == "suggested" ? "active" : ""}
                          onClick={(e) => {
                            coun(e, { data: "suggested" });
                          }}
                        >
                          <a>suggested </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {shortlist == "self-shortlisted" ? (
                  <div>
                    {shortListedUniversity.length == 0 ? (
                      <div className="p-2">
                        <div className="appli-box shade1">
                          <div className="col-md-12 appli-box-t">
                            <p>No shortlisted university found.</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {shortListedUniversity.length > 0 &&
                          shortListedUniversity?.map((data: any, i: any) => {
                            return (
                              <div className="shortlist-close">
                                <span
                                  onClick={(e) =>
                                    hendleLike(data?.university_id)
                                  }
                                  className="close-1"
                                >
                                  &times;
                                </span>
                                <div className="short-list-box">
                                  <div className="short-list-uni">
                                    {/* <img src="https://abym.in/dev-url/admissify/fe-html/admissify/assets/img/university.png" /> */}
                                    {data?.ad_university != null &&
                                    data?.ad_university?.university_logo !==
                                      null ? (
                                      <img
                                        src={
                                          baseurl +
                                          data?.ad_university?.university_logo
                                        }
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/noImage.jpeg"
                                        height="160px"
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <div className="shortlist-about">
                                    <h1>
                                      {data?.ad_university?.university_name}
                                    </h1>
                                    <p>
                                      <StarRatings
                                        rating={
                                          parseFloat(
                                            data?.ad_university?.rating
                                          )
                                            ? parseFloat(
                                                data?.ad_university?.rating
                                              )
                                            : 0
                                        }
                                        starRatedColor="#fcbd30"
                                        starDimension="13px"
                                        starSpacing="0px"
                                      />
                                    </p>
                                    <div className="item-address">
                                      {data?.ad_university?.university_type == 1
                                        ? "Private"
                                        : data?.ad_university
                                            ?.university_type == 2
                                        ? "Public"
                                        : ""}
                                    </div>{" "}
                                    <p className="c-time">
                                      {
                                        data?.ad_university?.Country
                                          ?.country_name
                                      }
                                    </p>
                                    {data?.suitability ? (
                                      <p className="c-time">
                                        Suitability :{" "}
                                        <b style={{ color: "#187273" }}>
                                          {data?.suitability}
                                        </b>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <div className="text-center appli-btn">
                                      <a
                                        onClick={() => {
                                          history.push(
                                            "/university/" +
                                              data?.ad_university?.slug,
                                            {
                                              university_id:
                                                data?.university_id,
                                            }
                                          );
                                        }}
                                        className="shortlist-appli-btn"
                                        tabIndex={0}
                                      >
                                        apply Now
                                      </a>
                                      <div className="counsellor-btn">
                                        <a
                                          href="javascript:void(0)"
                                          className="get-counsellor-btn"
                                          tabIndex={0}
                                          onClick={() => {
                                            history.push(
                                              "/counsellor-and-mentor",
                                              {
                                                country_id:
                                                  data?.ad_university
                                                    ?.country_id,
                                                country_name:
                                                  data?.ad_university?.Country
                                                    ?.country_name,
                                              }
                                            );
                                          }}
                                        >
                                          get counsellor
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {shortlist == "suggested" ? (
                  <div>
                    {getshortListedCourse.length == 0 ? (
                      <div className="p-2">
                        <div className="appli-box shade1">
                          <div className="col-md-12 appli-box-t">
                            <p>No suggested university course found.</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {getshortListedCourse.length > 0 &&
                          getshortListedCourse?.map((data: any, i: any) => {
                            return (
                              <div className="shortlist-close" key={i}>
                                <div className="box-suggested">
                                  <div className="row">
                                    <div className="col-md-4 text-center pr-0">
                                      <div className="short-list-uni">
                                        {data?.ShortlistedUniversity != null &&
                                        data?.ShortlistedUniversity
                                          ?.university_logo !== null ? (
                                          <img
                                            src={
                                              baseurl +
                                              data?.ShortlistedUniversity
                                                ?.university_logo
                                            }
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/noImage.jpeg"
                                            height="160px"
                                            width="100%"
                                          />
                                        )}
                                      </div>

                                      <div className="shortlist-about">
                                        <h1>
                                          {
                                            data?.ShortlistedUniversity
                                              ?.university_name
                                          }
                                        </h1>
                                        <p>
                                          <StarRatings
                                            rating={
                                              parseFloat(
                                                data?.ShortlistedUniversity
                                                  ?.rating
                                              )
                                                ? parseFloat(
                                                    data?.ShortlistedUniversity
                                                      ?.rating
                                                  )
                                                : 0
                                            }
                                            starRatedColor="#fcbd30"
                                            starDimension="13px"
                                            starSpacing="0px"
                                          />
                                        </p>
                                        <div className="d-flex justify-content-center align-items-center">
                                          <div className="item-address">
                                            {data?.ShortlistedUniversity
                                              ?.university_type == 1
                                              ? "Private"
                                              : data?.ShortlistedUniversity
                                                  ?.university_type == 2
                                              ? "Public"
                                              : ""}
                                          </div>{" "}
                                          {data?.ShortlistedUniversity
                                            ?.university_type && <span>|</span>}
                                          <p className="c-time">
                                            {
                                              data?.ShortlistedUniversity
                                                ?.Country?.country_name
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-8 pl-0">
                                      <div className="studywrp courses-shorted">
                                        <div className="bgstudy-short">
                                          <div className="row m-0">
                                            <div className="col-sm-12 col-lg-9 text-left">
                                              <h6>
                                                {
                                                  data
                                                    ?.ShortlistedUniversityCourse
                                                    ?.university_course
                                                }{" "}
                                                <br />
                                                {data
                                                  ?.ShortlistedUniversityCourse
                                                  ?.course_type == 1
                                                  ? "full time"
                                                  : data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.course_type == 2
                                                  ? "part time"
                                                  : data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.course_type == 3
                                                  ? "weekend"
                                                  : data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.course_type == 4
                                                  ? "other"
                                                  : ""}
                                              </h6>
                                            </div>
                                            <div className="col-sm-12 col-lg-3 p-0">
                                              <div className="bg-color-badge">
                                                <h5>
                                                  <span>
                                                    {
                                                      data
                                                        ?.ShortlistedUniversityCourse
                                                        ?.fee_currency_code
                                                    }
                                                  </span>{" "}
                                                  {data
                                                    ?.ShortlistedUniversityCourse
                                                    ?.first_yr_fee
                                                    ? data
                                                        ?.ShortlistedUniversityCourse
                                                        ?.first_yr_fee + "/year"
                                                    : ""}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="shortdetailstudy">
                                          <div className="row m-0">
                                            <div className="col-sm-12 col-lg-12 text-left pr-0">
                                              <div className="bg-light-badge">
                                                <div className="row">
                                                  <div className="col-md-4 d-flex p-0 pl-3">
                                                    <div className="icon">
                                                      <img
                                                        src="/assets/img/time.png"
                                                        alt=""
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="icon-info">
                                                      <b>
                                                        {
                                                          data
                                                            ?.ShortlistedUniversityCourse
                                                            ?.duration
                                                        }
                                                      </b>
                                                      <div>course duration</div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 d-flex p-0">
                                                    <div className="icon">
                                                      <img
                                                        src="/assets/img/date.png"
                                                        alt=""
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="icon-info">
                                                      <b>26-Aug-2039</b>
                                                      <div>
                                                        application deadline
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 d-flex p-0">
                                                    <div className="icon">
                                                      <img
                                                        src="/assets/img/library-building.png"
                                                        alt=""
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="icon-info last-icon-info">
                                                      <b>
                                                        {data
                                                          ?.ShortlistedUniversityCourse
                                                          ?.entry_requirements ? (
                                                          indexMore ===
                                                            data
                                                              ?.ShortlistedUniversityCourse
                                                              ?.id &&
                                                          data?.ShortlistedUniversityCourse?.entry_requirements?.split(
                                                            " "
                                                          ).length <
                                                            readMore ? (
                                                            <p className="course__font">
                                                              {
                                                                data
                                                                  ?.ShortlistedUniversityCourse
                                                                  ?.entry_requirements
                                                              }
                                                            </p>
                                                          ) : (
                                                            <p className="course__font">
                                                              {" "}
                                                              {data?.ShortlistedUniversityCourse?.entry_requirements
                                                                ?.split(" ")
                                                                .splice(0, 5)
                                                                .join(" ")}
                                                              .{" "}
                                                              <a
                                                                className="read-more-new"
                                                                href="#"
                                                                onClick={(
                                                                  e: any
                                                                ) => {
                                                                  e.preventDefault();
                                                                  setreadMore(
                                                                    data?.ShortlistedUniversityCourse?.entry_requirements?.split(
                                                                      " "
                                                                    ).length &&
                                                                      data?.ShortlistedUniversityCourse?.entry_requirements?.split(
                                                                        " "
                                                                      ).length +
                                                                        1
                                                                  );
                                                                  setIndexMore(
                                                                    data
                                                                      ?.ShortlistedUniversityCourse
                                                                      ?.id
                                                                  );
                                                                }}
                                                              >
                                                                read more
                                                              </a>
                                                            </p>
                                                          )
                                                        ) : (
                                                          <p></p>
                                                        )}
                                                      </b>
                                                      <div>
                                                        entry requirement
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="examscore">
                                                  exam scores &nbsp; IELTS{" "}
                                                  <b>
                                                    {data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.iselts_score
                                                      ? data
                                                          ?.ShortlistedUniversityCourse
                                                          ?.iselts_score
                                                      : 0}
                                                  </b>{" "}
                                                  | TOEFL{" "}
                                                  <b>
                                                    {data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.toefl_scrore
                                                      ? data
                                                          ?.ShortlistedUniversityCourse
                                                          ?.toefl_scrore
                                                      : 0}
                                                  </b>{" "}
                                                  | PTE{" "}
                                                  <b>
                                                    {data
                                                      ?.ShortlistedUniversityCourse
                                                      ?.pte_score
                                                      ? data
                                                          ?.ShortlistedUniversityCourse
                                                          ?.pte_score
                                                      : 0}
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row m-0">
                                            <div className="col-4">
                                              {(() => {
                                                var obtainMarks = userInterests
                                                  ? userInterests[0]
                                                      ?.highest_edu
                                                      ?.Achieved_Or_Expected_marks
                                                  : 0;
                                                var resultType = userInterests
                                                  ? userInterests[0]
                                                      ?.highest_edu?.resultType
                                                  : null;
                                                if (resultType == "CGPA") {
                                                  obtainMarks =
                                                    obtainMarks * 10;
                                                }
                                                if (resultType == "Grade") {
                                                  if (
                                                    obtainMarks.toLowerCase() ==
                                                    "a"
                                                  ) {
                                                    obtainMarks = 85;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "b"
                                                  ) {
                                                    obtainMarks = 75;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "c"
                                                  ) {
                                                    obtainMarks = 65;
                                                  } else if (
                                                    obtainMarks.toLowerCase() ==
                                                    "d"
                                                  ) {
                                                    obtainMarks = 55;
                                                  }
                                                }

                                                if (IsAvailability == true) {
                                                  return (
                                                    <div className="option suggested-option text-right">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="eligibility_btn"
                                                      >
                                                        eligibility :{" "}
                                                        <b>
                                                          {obtainMarks >= 85 ? (
                                                            <div
                                                              style={{
                                                                color:
                                                                  "#187172",
                                                              }}
                                                            >
                                                              high
                                                            </div>
                                                          ) : obtainMarks >=
                                                              70 &&
                                                            obtainMarks < 85 ? (
                                                            <div
                                                              style={{
                                                                color: "orange",
                                                              }}
                                                            >
                                                              moderate
                                                            </div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              low
                                                            </div>
                                                          )}
                                                        </b>
                                                      </a>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="option text-right">
                                                      {(() => {
                                                        if (user?.id) {
                                                          return (
                                                            <a
                                                              href="/applynow"
                                                              className="checkbtn eligibility_btn"
                                                            >
                                                              check eligibility{" "}
                                                            </a>
                                                          );
                                                        } else {
                                                          return (
                                                            <a
                                                              onClick={
                                                                hendleApplyNow
                                                              }
                                                              className="checkbtn eligibility_btn"
                                                            >
                                                              check eligibility{" "}
                                                            </a>
                                                          );
                                                        }
                                                      })()}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </div>

                                            <div className="col-4">
                                              <div className="option">
                                                {data?.suitability ? (
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="eligibility_btn"
                                                  >
                                                    suitability :{" "}
                                                    <b
                                                      style={{
                                                        color: "#187273",
                                                      }}
                                                    >
                                                      {data?.suitability}
                                                    </b>
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>

                                            <div className="col-4">
                                              <div className="option text-right">
                                                <div
                                                  style={{ marginTop: "5px" }}
                                                >
                                                  <label
                                                    onClick={(e: any) =>
                                                      handleApplyNow(e)
                                                    }
                                                    data-course-index={i}
                                                    className={
                                                      data.hasApplied
                                                        ? "applied-label"
                                                        : "apply-now-label"
                                                    }
                                                    htmlFor="_apply_now_55585"
                                                  >
                                                    {data.hasApplied
                                                      ? "Applied/See status"
                                                      : "Apply Now"}
                                                    {/* {isApplied ? 'Applied' : 'Apply Now'} */}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.coupon_code}</span>
                    )}{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shortlist;
