import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import MobileLayout from "./MobileContainer";
import Layout from "./layout";

const App = () => {
  const [mobileAppSuggestion, setMobileAppSuggestion] = useState(false);

  useEffect(() => {
    AOS.init({ once: false });
    AOS.refresh();
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setMobileAppSuggestion(window.innerWidth < 600);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to resize event
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/app" component={MobileLayout}></Route>
        <Route path="/" component={Layout}></Route>
      </Switch>
    </Router>
  );
};

export default App;
