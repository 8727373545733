import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UniversityCourseAction,
  counselloerAction,
  streamAction,
  UniversityAction,
  applyNoAction,
} from "../../redux/common/action";
import MenuOutSideClick from "../../layout/header/outsideClick";
import { useHistory } from "react-router-dom";
import AdSlider from "../AdSlider/AdSlider";
import CourseFilter from "../coursefilter/CourseFilter";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { URL } from "../../redux/common/url";
import Seo from "../../Component/Seo/Seo";
import { sorting } from "../../Utils/Common/Common";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";
interface Stream {
  stream_name: string;
}

function Program() {
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const dispatch = useDispatch();
  const history = useHistory();
  const [course, setcourse] = useState<any[]>([]);
  const [search, setsearch] = useState<any[]>([]);
  const [courseid, setcourseid] = useState<any[]>([]);
  const [unicourseList, setUniCourseList] = useState<any[]>([]);
  const [studylevel, setstudylevel] = useState<any>("1");
  const [sortBy, setosrtBy] = useState<any>();
  const [limit, setLimit] = useState<any>(50);
  const [CurrentLevel, setCurrentLevel] = useState<any>("");
  const [CurrentStreamId, setCurrentStreamId] = useState<any>("");
  const baseurl = URL.API_BASE_URL;
  const id: any = useLocation();
  const [loadmore, setloadmore] = useState<any>(50);
  const [offset, setoffset] = useState<any>(0);
  const [readMore, setreadMore] = useState<any>(5);
  const [searchRe, setsearchRe] = useState<any>("");
  const [CourseId, setCourseId] = useState<any[]>([]);
  const [SelectedCourseCount, setSelectedCourseCount] = useState<any>("");
  const [data, setData] = useState([]);
  const [countryOpne, setcountryOpne] = useState<any>(isMobile ? true : false);
  const [indexMore, setIndexMore] = useState<any>("");
  const [universityId, setUniversityId] = useState<any>("");
  const university: any = useSelector((state: any) =>
    state.unversity.universityById ? state.unversity.universityById : []
  );

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  var userInterests = localStorage.getItem("userInterest");

  var IsAvailability = false;
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
  }
  if (userInterests) {
    IsAvailability = true;
  }

  // console.log('isMobile',isMobile);
  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const openModelPopup = (
    event,
    id: any,
    level: any,
    streamId: any,
    UniversityId: any
  ) => {
    setCurrentLevel(level);
    setCurrentStreamId(streamId);
    setUniversityId(UniversityId);
    var maxChecked = $(".apply-now-checkbox").filter(":checked").length;
    var x =
      maxChecked +
      appliedCourseList.filter(
        (item: any) => item.university_id == UniversityId
      ).length;

    if (x > 3) {
      maxChecked = x;
      // CourseId.push(appliedCoursesArray);
      setSelectedCourseCount(maxChecked);
    }
    if (maxChecked <= 3) {
      if ($("#_apply_now_" + id).prop("checked")) {
        CourseId.push(id);
        setSelectedCourseCount(maxChecked);
      } else {
        setSelectedCourseCount(maxChecked);
      }
    } else {
      $("#_apply_now_" + id).attr("checked", false);
      toast.error("You can apply maximum 3 courses.");
    }
    $("#__apply_now_" + id).click();
  };
  const handleApplyNow = (event: any) => {
    var CourseIdFilter = CourseId.filter(function (a) {
      if (!this[a]) {
        this[a] = 1;
        return a;
      }
    }, {});

    if (CourseIdFilter.length > 0) {
      var postData = {
        student_id: user?.id,
        mobile: user?.mobile,
        university_id: universityId,
        course_level: userInterests
          ? userInterests[0]?.course_level
          : CurrentLevel,
        stream_id: userInterests
          ? userInterests[0]?.streamData?.streamId
          : CurrentStreamId,
        intake: userInterests ? userInterests[0]?.intake : null,
        course_id: CourseIdFilter,
        objective: userInterests ? userInterests[0].objective?.objective : null,
        country_id: university?.country_id,
        highest_edu: userInterests ? userInterests[0]?.highest_edu : null,
        tests: userInterests ? userInterests[0]?.tests : null,
      };

      dispatch(applyNoAction.applyNow(postData, history));

      if (user?.id && user?.mobile) {
        dispatch(
          UniversityAction.appliedCoursesFrontendUniStudMain({
            student_id: user?.id,
            mobile: user?.mobile,
          })
        );
      }
    } else {
      toast.error("Please choose at least 1 course.");
    }
  };

  const universitycourse: any = useSelector((state: any) =>
    state.universitycourse.universitycourse
      ? state.universitycourse.universitycourse
      : []
  );
  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const appliedCourses: any = useSelector((state: any) =>
    state.unversity.appliedCoursesFrontendUniStud
      ? state.unversity.appliedCoursesFrontendUniStud
      : []
  );

  var [appliedCoursesArray, setAppliedCoursesArray]: any[] = useState<any[]>(
    []
  );
  var [appliedCourseList, setAppliedCoursesList]: any[] = useState<any[]>([]);

  useEffect(() => {
    if (appliedCourses?.status) {
      setAppliedCoursesArray(
        appliedCourses?.data?.map((v: any, k: any) => v.course_id)
      );
      setAppliedCoursesList(appliedCourses?.data);
    }
  }, [appliedCourses]);

  const allCourse: any = useSelector((state: any) =>
    state.universitycourse.allCourse ? state.universitycourse.allCourse : []
  );

  const activeStream: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );
  const StreamName = activeStream.map((s: any) => s.stream_name);
  const filteredStream = activeStream.filter(
    ({ stream_name }: Stream, index: number, self: Stream[]) =>
      !self.some((s, i) => i > index && s.stream_name === stream_name)
  );

  const activecourse_ =
    allCourse &&
    allCourse?.filter((data: any) => {
      return data.level == studylevel;
    });

  const activecourse = activecourse_.sort((a: any, b: any) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  useEffect(() => {
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: "",

        CourseLevel: studylevel,
        Offset: 0,
        Limit: 50,
      })
    );

    if (user?.id && user?.mobile) {
      dispatch(
        UniversityAction.appliedCoursesFrontendUniStudMain({
          student_id: user?.id,
          mobile: user?.mobile,
        })
      );
    }
    return () => {};
  }, [studylevel]);

  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(counselloerAction.getAllCounsellor({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (offset == 0) {
      setUniCourseList(universitycourse);
    } else {
      setUniCourseList([...unicourseList, ...universitycourse]);
    }
  }, [universitycourse]);

  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  // }, [])

  const sortByName = (e: any) => {
    setosrtBy(e);
    setoffset(0);

    const sortedUni = unicourseList.sort((a: any, b: any) => {
      switch (e) {
        case "lowestfee":
          return (
            Number(a.first_yr_fee.replace(/\D/g, "")) -
            Number(b.first_yr_fee.replace(/\D/g, ""))
          );
        case "highestfee":
          return (
            Number(b.first_yr_fee.replace(/\D/g, "")) -
            Number(a.first_yr_fee.replace(/\D/g, ""))
          );
        default:
          return 0;
      }
    });

    setUniCourseList(sortedUni);
  };
  const levelofstudy = (e: any) => {
    setoffset(0);
    setosrtBy("");
    setcourseid([]);
    setcourse([]);
    setstudylevel(e);
  };
  const hendleLodemore = (e: any) => {
    let arrayid = [...courseid];
    setloadmore(loadmore);
    setoffset(offset + 50);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: searchRe ? searchRe : "",
        Limit: loadmore,

        CourseLevel: studylevel,
        Offset: offset + 50,
        CourseId: arrayid.length > 0 ? arrayid : "",
      })
    );
    return () => {};
  };
  const uniAndCouresHendle = (e: any) => {
    setsearchRe(e);
    setoffset(0);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: e,

        CourseLevel: studylevel,
        Offset: 0,
        Limit: 50,
        /*  limit:10 */
      })
    );
    return () => {};
  };

  const hendlSearchCourseByStream = (data: any) => {
    setcourse([]);
    setcourseid([]);
    // var Ids = data.split('&');
    setsearch(data);
    setsearchRe(data);
    setoffset(0);
    dispatch(
      UniversityCourseAction.getAllUniCourses({
        SearchTerm: data ? data : "",

        CourseLevel: studylevel,
        Limit: 50,
        Offset: 0,
      })
    );

    if (user?.id && user?.mobile) {
      dispatch(
        UniversityAction.appliedCoursesFrontendUniStudMain({
          student_id: user?.id,
          mobile: user?.mobile,
        })
      );
    }
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };
  const hendlClickCourse = (event: any) => {
    setsearchRe("");
    let array = [...course];
    let arrayid = [...courseid];
    if (event.target.checked) {
      arrayid.push(event.target.value);
      array.push(event.target.name);
      setcourseid(arrayid);
      setcourse(array);
      setoffset(0);
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid,

          CourseLevel: studylevel,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );

      if (user?.id && user?.mobile) {
        dispatch(
          UniversityAction.appliedCoursesFrontendUniStudMain({
            student_id: user?.id,
            mobile: user?.mobile,
          })
        );
      }
    } else {
      let coun = array.indexOf(event.target.name);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcourseid(arrayid);
        setcourse(array);
        setoffset(0);
        dispatch(
          UniversityCourseAction.getAllUniCourses({
            CourseId: arrayid.length > 0 ? arrayid : "",

            CourseLevel: studylevel,
            SearchTerm: "",
            Offset: 0,
            Limit: 50,
          })
        );
      }
    }
  };
  const removeCourse = (event: any, value: any) => {
    setsearchRe("");
    let array = [...course];
    let arrayid = [...courseid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcourse(array);
      setcourseid(arrayid);
      setoffset(0);
      dispatch(
        UniversityCourseAction.getAllUniCourses({
          CourseId: arrayid.length > 0 ? arrayid : "",

          CourseLevel: studylevel,
          SearchTerm: "",
          Offset: 0,
          Limit: 50,
        })
      );

      if (user?.id && user?.mobile) {
        dispatch(
          UniversityAction.appliedCoursesFrontendUniStudMain({
            student_id: user?.id,
            mobile: user?.mobile,
          })
        );
      }
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);

  return (
    <div>
      <Seo slug={window.location.pathname} />
      <ScrollToTop />
      <section className="unifilter-page unifilter__mob" id="program">
        <div className="container">
          <Breadcrumb page={"Course"} data={{ slug: "", path: "" }} />
          <div className="program-search shade1">
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    ref={anchorRef1}
                    type="text"
                    className="form-control"
                    placeholder="search stream and course"
                    value={searchRe}
                    onChange={(e) => {
                      uniAndCouresHendle(e.target.value);
                    }}
                  />
                  {anchor1 && (
                    <div
                      className="searchuniversitiesandcourses"
                      style={{ padding: "0px", width: "20%" }}
                    >
                      <ul>
                        {filteredStream &&
                          filteredStream?.map((data: any, i: any) => {
                            return (
                              i < 15 && (
                                <li
                                  onClick={() => {
                                    hendlSearchCourseByStream(
                                      data?.stream_name
                                    );
                                    // setsearchRe("");
                                  }}
                                  key={i}
                                >
                                  {data?.stream_name}
                                </li>
                              )
                            );
                          })}
                      </ul>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="serchsuggest">
                  <p>search by</p>
                  <ul>
                    {filteredStream &&
                      filteredStream?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <a
                              onClick={(e: any) =>
                                hendlSearchCourseByStream(data?.stream_name)
                              }
                            >
                              {data?.stream_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-2 col-md-pull-10"
              // style={{ border: "1px solid red" }}
            >
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <h5>
                    <span>
                      selected <b>filters</b> <i className="fa fa-angle-up"></i>
                    </span>{" "}
                  </h5>
                  <ul
                    className={
                      course?.length !== 0
                        ? "clear-filter"
                        : "clear-filter filDisNone"
                    }
                  >
                    {course.map((data, index) => {
                      return (
                        <li key={index}>
                          {data}
                          <a onClick={(e: any) => removeCourse(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <!-- WIDGET --> */}

                {/* <CourseFilter
                  course={course}
                  setcourse={setcourse}
                  courseid={courseid}
                  setcourseid={setcourseid}
                /> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span onClick={hendlecountryOpne}>
                      filter by <b>course</b>
                      {countryOpne == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={countryOpne == true ? "countryHide" : ""}>
                    {countryOpne == false &&
                      activecourse &&
                      activecourse?.map((data: any, i: any) => {
                        if (data?.status == 1)
                          return (
                            <li key={i}>
                              <label>
                                <input
                                  type="checkbox"
                                  name={data?.title}
                                  value={data?.id}
                                  checked={courseid.includes(
                                    data?.id.toString()
                                  )}
                                  onChange={(e: any) => hendlClickCourse(e)}
                                />
                                <i className="awe-icon awe-icon-check"></i>
                                {data?.title?.length > 20
                                  ? `${data?.title.substring(0, 20)}...`
                                  : data?.title}
                              </label>
                            </li>
                          );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
              <div className="banner-section1">
                <VerticalAddSlider />
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2">
              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  {/* <!-- ITEM --> */}
                  {seo?.header_content && (
                    <div className="about-uni-text shade1">
                      <SeoHeaderContent />
                    </div>
                  )}
                  <div className="course-offer-uni shade1 pb-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="headingmains text-center"></div>
                        <div className="all-center sub-section pb-0 pt-0">
                          <div className="row">
                            <div className="col-md-9">
                              <div className="lavelbox text-left">
                                <b>level of study</b>
                                <input
                                  type="radio"
                                  name="RadioGroup1"
                                  value="1"
                                  onClick={(e: any) =>
                                    levelofstudy(e.target.value)
                                  }
                                  id="RadioGroup1_0"
                                  checked={studylevel == "1"}
                                />
                                <label htmlFor="RadioGroup1_0">
                                  {" "}
                                  bachelor's{" "}
                                </label>

                                <input
                                  type="radio"
                                  name="RadioGroup1"
                                  value="2"
                                  onClick={(e: any) =>
                                    levelofstudy(e.target.value)
                                  }
                                  id="RadioGroup1_1"
                                  checked={studylevel == "2"}
                                />
                                <label htmlFor="RadioGroup1_1"> master's</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="page-top pull-right mb-4">
                                <select
                                  className="awe-select"
                                  onChange={(e: any) =>
                                    sortByName(e.target.value)
                                  }
                                >
                                  <option value="">sort by</option>
                                  <option value="lowestfee">lowest fees</option>
                                  <option value="highestfee">
                                    highest fees
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {unicourseList && unicourseList?.length == 0 ? (
                            <div className="nodataFound">
                              <img src="/assets/img/noDataFound.jpeg" alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            {unicourseList?.length > 0 &&
                              unicourseList?.map((data: any, index: any) => {
                                let date =
                                  data?.application_last_date != "0000-00-00" &&
                                  data?.application_last_date != null &&
                                  data?.application_last_date != "Invalid date"
                                    ? dateFormat(
                                        data?.application_last_date,
                                        "d-mmm-yyyy"
                                      )
                                    : "N/A";
                                const exam = JSON.parse(data?.exam_score);

                                console.log(
                                  data?.university_id,
                                  "university_id"
                                );

                                return (
                                  <div className="col-md-6" key={index}>
                                    <div className="studywrp shade1">
                                      <div className="bgstudy">
                                        <div className="row ">
                                          <div className="col-sm-12 col-lg-7 text-left">
                                            <h6>
                                              {data?.university_course} <br />
                                              {data?.course_type == 1
                                                ? "full time"
                                                : data?.course_type == 2
                                                ? "part time"
                                                : data?.course_type == 3
                                                ? "weekend"
                                                : data?.course_type == 4
                                                ? "other"
                                                : ""}
                                            </h6>
                                          </div>
                                          <div className="col-sm-12 col-lg-5">
                                            <h5>
                                              <span>
                                                {data?.fee_currency_code}
                                              </span>{" "}
                                              {data?.first_yr_fee}/year
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="detailstudy">
                                        <div className="row">
                                          <div className="col-sm-12 col-lg-12 text-left">
                                            <table
                                              width="100%"
                                              style={{ border: "0" }}
                                              cellSpacing="0"
                                              cellPadding="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/time.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            <b>
                                                              {data?.duration}
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              course&nbsp;duration
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/date.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            <b>{date}</b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              application&nbsp;deadline
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td>
                                                    <table
                                                      width="100%"
                                                      style={{ border: "0" }}
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody className="course__">
                                                        <tr>
                                                          <td rowSpan={2}>
                                                            <img
                                                              src="/assets/img/library-building.png"
                                                              alt=""
                                                              className=""
                                                            />
                                                          </td>
                                                          <td>
                                                            {data?.entry_requirements ? (
                                                              indexMore ===
                                                                data?.id &&
                                                              data?.entry_requirements?.split(
                                                                " "
                                                              ).length <
                                                                readMore ? (
                                                                <p className="course__font">
                                                                  {
                                                                    data?.entry_requirements
                                                                  }
                                                                </p>
                                                              ) : (
                                                                <p className="course__font">
                                                                  {" "}
                                                                  {data?.entry_requirements
                                                                    ?.split(" ")
                                                                    .splice(
                                                                      0,
                                                                      5
                                                                    )
                                                                    .join(" ")}
                                                                  .{" "}
                                                                  <a
                                                                    className="read-more-new"
                                                                    href="#"
                                                                    onClick={(
                                                                      e: any
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setreadMore(
                                                                        data?.entry_requirements?.split(
                                                                          " "
                                                                        )
                                                                          .length &&
                                                                          data?.entry_requirements?.split(
                                                                            " "
                                                                          )
                                                                            .length +
                                                                            1
                                                                      );
                                                                      setIndexMore(
                                                                        data?.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    read more
                                                                  </a>
                                                                </p>
                                                              )
                                                            ) : (
                                                              <p></p>
                                                            )}
                                                            {/* we can use it easy way*/}
                                                            {/* <p className={indexMore === data?.id ? "course__font" : "course__font turncate1"}>{data?.entry_requirements}</p>
                                                              {indexMore === data?.id ? null : 
                                                              <a className="read-more-new" href="#" onClick={(e:any) => {e.preventDefault(); setIndexMore(data?.id);}}>read more</a>
                                                              } */}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <span>
                                                              entry&nbsp;requirement
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div className="examscore">
                                              {/* exam scores &nbsp;{" "}
                                              {typeof exam == "object"
                                                ? exam?.map(
                                                    (datas: any, i: any) => {
                                                      return (
                                                        <span key={i}>
                                                          {" " + datas?.title}{" "}
                                                          <b>{datas?.value}</b>{" "}
                                                          {i > 0 && i - 1
                                                            ? ""
                                                            : "|"}
                                                        </span>
                                                      );
                                                    }
                                                  )
                                                : ""} */}
                                              exam scores &nbsp; IELTS{" "}
                                              <b>
                                                {data?.iselts_score
                                                  ? data?.iselts_score
                                                  : 0}
                                              </b>{" "}
                                              | TOEFL{" "}
                                              <b>
                                                {data?.toefl_scrore
                                                  ? data?.toefl_scrore
                                                  : 0}
                                              </b>{" "}
                                              | PTE{" "}
                                              <b>
                                                {data?.pte_score
                                                  ? data?.pte_score
                                                  : 0}
                                              </b>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <div className="option">
                                                  <span>options available</span>
                                                  M.B.A Entrepreneurship
                                                </div>
                                              </div>
                                              <div className="col-6">
                                                {(() => {
                                                  var obtainMarks =
                                                    userInterests
                                                      ? userInterests[0]
                                                          ?.highest_edu
                                                          ?.Achieved_Or_Expected_marks
                                                      : 0;
                                                  var resultType = userInterests
                                                    ? userInterests[0]
                                                        ?.highest_edu
                                                        ?.resultType
                                                    : null;
                                                  if (resultType == "CGPA") {
                                                    obtainMarks =
                                                      obtainMarks * 10;
                                                  }
                                                  if (resultType == "Grade") {
                                                    if (
                                                      obtainMarks.toLowerCase() ==
                                                      "a"
                                                    ) {
                                                      obtainMarks = 85;
                                                    } else if (
                                                      obtainMarks.toLowerCase() ==
                                                      "b"
                                                    ) {
                                                      obtainMarks = 75;
                                                    } else if (
                                                      obtainMarks.toLowerCase() ==
                                                      "c"
                                                    ) {
                                                      obtainMarks = 65;
                                                    } else if (
                                                      obtainMarks.toLowerCase() ==
                                                      "d"
                                                    ) {
                                                      obtainMarks = 55;
                                                    }
                                                  }

                                                  if (IsAvailability == true) {
                                                    return (
                                                      <div className="option text-right">
                                                        <a
                                                          href="javascript:void(0)"
                                                          className="eligibility_btn"
                                                        >
                                                          eligibility :{" "}
                                                          <b>
                                                            {obtainMarks >=
                                                            85 ? (
                                                              <strong
                                                                style={{
                                                                  color:
                                                                    "green",
                                                                }}
                                                              >
                                                                high
                                                              </strong>
                                                            ) : obtainMarks >=
                                                                70 &&
                                                              obtainMarks <
                                                                85 ? (
                                                              <strong
                                                                style={{
                                                                  color:
                                                                    "orange",
                                                                }}
                                                              >
                                                                moderate
                                                              </strong>
                                                            ) : (
                                                              <strong
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                low
                                                              </strong>
                                                            )}
                                                          </b>
                                                        </a>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className="option text-right">
                                                        {(() => {
                                                          if (user?.id) {
                                                            return (
                                                              <a
                                                                href="/applynow"
                                                                className="checkbtn eligibility_btn"
                                                              >
                                                                check
                                                                eligibility{" "}
                                                              </a>
                                                            );
                                                          } else {
                                                            return (
                                                              <a
                                                                onClick={
                                                                  hendleApplyNow
                                                                }
                                                                className="checkbtn eligibility_btn"
                                                              >
                                                                check
                                                                eligibility{" "}
                                                              </a>
                                                            );
                                                          }
                                                        })()}
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <div className="option">
                                                  Offered by{" "}
                                                  <p className="color-green">
                                                    {
                                                      data?.ad_university
                                                        ?.university_name
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                              {(() => {
                                                if (IsAvailability == true) {
                                                  return (
                                                    <div className="col-6">
                                                      <div className="option text-right">
                                                        <div
                                                          style={{
                                                            marginTop: "5px",
                                                          }}
                                                        >
                                                          <div
                                                            id={
                                                              "__apply_now_" +
                                                              data?.id
                                                            }
                                                            className="hide"
                                                            data-toggle="modal"
                                                            data-target="#date_foo"
                                                          ></div>
                                                          <input
                                                            onClick={(e) =>
                                                              openModelPopup(
                                                                e,
                                                                data?.id,
                                                                data?.course_level,
                                                                data?.stream_id,
                                                                data?.university_id
                                                              )
                                                            }
                                                            className="apply-now-checkbox"
                                                            style={{
                                                              display:
                                                                appliedCoursesArray.includes(
                                                                  data?.id
                                                                ) ||
                                                                appliedCourseList.filter(
                                                                  (item: any) =>
                                                                    item.university_id ==
                                                                    data?.university_id
                                                                ).length >= 3
                                                                  ? "none"
                                                                  : "",
                                                            }}
                                                            type="checkbox"
                                                            id={
                                                              "_apply_now_" +
                                                              data?.id
                                                            }
                                                            disabled={
                                                              appliedCoursesArray.includes(
                                                                data?.id
                                                              ) ||
                                                              appliedCourseList.filter(
                                                                (item: any) =>
                                                                  item.university_id ==
                                                                  data?.university_id
                                                              ).length >= 3
                                                            }
                                                          />
                                                          <label
                                                            className="apply-now-label"
                                                            for={
                                                              "_apply_now_" +
                                                              data?.id
                                                            }
                                                          >
                                                            {appliedCoursesArray.includes(
                                                              data?.id
                                                            )
                                                              ? "Applied"
                                                              : appliedCourseList.filter(
                                                                  (item: any) =>
                                                                    item.university_id ==
                                                                    data?.university_id
                                                                ).length >= 3
                                                              ? "Max Limit Exceeded"
                                                              : "Apply Now"}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          </div>
                                          <div className="col-sm-12 col-lg-3">
                                            {/* <a href="" className="checkbtn">
                                              check eligibility
                                            </a> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            {/* <div className="col-12">
                              <AdSlider />
                            </div> */}
                          </div>
                          {universitycourse?.length == 50 ? (
                            <div className="all-center my-2 ">
                              <a
                                onClick={(e) => {
                                  hendleLodemore(e);
                                }}
                                className="view-more readbtn"
                              >
                                Load More
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {seo?.footer_content && (
                    <div className="about-uni-text shade1">
                      <SeoFooterContent />
                    </div>
                  )}
                  {/* <!-- END / ITEM --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 
      Apply eligibility popup
      */}
      <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="row app_now_">
                <div className="col-lg-12">
                  <p className="app_now_head">
                    Are you sure want to apply for this course?
                  </p>
                  <p className="app_max_err">*You can choose any 1 course.</p>
                  <p className="">
                    You have selected{" "}
                    {SelectedCourseCount ? SelectedCourseCount : 0} out of 1
                    course.
                  </p>
                </div>
              </div>
              <figcaption className="details-box pb-3 pt-0">
                <div className="bttns text-center">
                  {(() => {
                    if (SelectedCourseCount > 0) {
                      return (
                        <a className="applybtn bggreadent">
                          <span
                            className="btn btn-success font-weight-bold _submit_btn"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e: any) => handleApplyNow(e)}
                          >
                            apply now
                          </span>
                        </a>
                      );
                    }
                  })()}
                  {(() => {
                    if (SelectedCourseCount < 3) {
                      return (
                        <span
                          className="font-weight-bold _app_add_m"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          + add more
                        </span>
                      );
                    } else {
                      return (
                        <p className="app_max_ pt-2">
                          *you have already reached max selection of courses
                        </p>
                      );
                    }
                  })()}
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Program;
